import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/vuex'

//toast notification import
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-bootstrap.css';
//end toast notification import



// plugin
import {i18n} from "@/plugins/i18n";
// end plugin

// bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Echarts
import VueEcharts from 'vue-echarts';

// font awesome
import '@fortawesome/fontawesome-free/css/all.css';
import '@vuepic/vue-datepicker/dist/main.css';
// end font awesome


/*system style*/
import '@/assets/css/stylesheets/themes/theme.scss'
import '@/assets/css/stylesheets/menu-icon.scss'
import "@/assets/scss/variables.scss"
import "@/assets/scss/styles.scss";

import PhosphorIcons from "@phosphor-icons/vue"
import '@/assets/css/stylesheets/phosphor-icons-stylesheet.css'

/*primevue */
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css'






createApp(App)
    .use(store)
    .use(PrimeVue, {
        unstyled: false
    })
    .use(router)
    .use(PhosphorIcons)
    .component('v-chart', VueEcharts)
    .use(i18n)
    .use(ToastPlugin,{position:"top-right"})
    .mount('#app')
