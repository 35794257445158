import {createRouter, createWebHashHistory} from "vue-router";
import CryptoJS from "crypto-js";
/**
 *Contains all the routes accessible by SMS Billing 5.0
 * The routes may be like:
 * <p>Single Parent routes:
 * <ul>
 *     <li> Login </li>
 *     <li> Reve Secure </li>
 *     <li> Reset Password </li>
 *     <li> Update Password </li>
 *     <li> Forbidden </li>
 *     <li> Dashboard </li>
 *     <li> Invoice Bill Generation </li>
 * </ul>
 * <p> Other routes are children of Dashboard route.</p>
 * <p> Children routes may be of the following types:</p>
 * <ul>
 *     <li><b>Search/Single page:</b> These routes have the parent_directory/child_directory url attached to them, intended for search pages.</li>
 *     <li><b>Add/Assign/Upload page:</b> These routes have the parent_directory/child_directory-(add/assign/upload) url attached to them.</li>
 *     <li><b>Edit page:</b> These routes have the parent_directory/child_directory-edit url attached to them. They also take a parameter(id) to fetch the data from the corresponding backend controller.</li>
 * </ul>
 */
const routes = [
    //notes:
    // Convention
    // path: small letter with dash
    // name: similar to Component-name
    {
        path: '/:pathMatch(.*)',
        name: "Not Found",
        component: () => import("../components/utilComponents/Forbidden.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login/LoginComponent.vue"),
    },
    {
        path: "/reve-secure",
        name: "reveSecure",
        component: () => import("../views/login/ReveSecureView.vue"),
        props: route => ({loginDetails: JSON.parse(CryptoJS.AES.decrypt(route.query.info, 'secret-key').toString(CryptoJS.enc.Utf8))}),
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("../views/login/ResetPasswordView.vue"),
    },
    {
        path: "/update-password/:id",
        name: "UpdatePassword",
        component: () => import("../views/login/UpdatePasswordView.vue"),
    },
    //error
    {
        path: "/forbidden",
        name: "ForbiddenPage",
        component: () => import("../components/utilComponents/Forbidden.vue"),
    },
    {
        path: "/",
        redirect: "/dashboard",
        name: "MainPage",
        component: () => import("../components/MainPageComponent.vue"),
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                menuID: 1,
                subMenuID: 1,
                component: () => import("../views/dashboard/DashboardView.vue"),
            },

            {
                path: "/account-details",
                name: "AccountDetails",
                menuID: 1,
                subMenuID: 1,
                component: () => import("../views/accountDetails/AccountDetails.vue"),
            },

            {
                path: "/technical-details",
                name: "TechnicalDetails",
                menuID: 1,
                subMenuID: 1,
                component: () => import("../views/technicalDetails/TechnicalDetails.vue"),
            },


            //client
            {
                path: "/client/sms-client",
                name: "SMSClient",
                component: () =>
                    import("../views/client/smsClient/SMSClientComponent.vue"),
            },
            {
                path: "/client/sms-client-add",
                name: "SMSClientAdd",
                component: () =>
                    import("../views/client/smsClient/SMSClientAdd.vue"),
            },
            {
                path: "/client/sms-client-edit/:id",
                name: "SMSClientEdit",
                menuID: 640,
                subMenuID: 646,
                component: () =>
                    import("../views/client/smsClient/SMSClientEdit.vue"),
            },

            {
                path: "/client/smpp-profile",
                name: "SMPPProfile",
                component: () =>
                    import("../views/client/smppProfile/SMPPProfileComponent.vue"),
            },
            {
                path: "/client/smpp-profile-add",
                name: "SMPPProfileAdd",
                component: () =>
                    import("../views/client/smppProfile/SMPPProfileAddEdit.vue"),
            },
            {
                path: "/client/smpp-profile-edit/:id",
                name: "SMPPProfileEdit",
                component: () =>
                    import("../views/client/smppProfile/SMPPProfileAddEdit.vue"),
            },
            {
                path: "/client/http-profile",
                name: "HTTPProfile",
                menuID: 640,
                subMenuID: 646,
                component: () =>
                    import("../views/client/httpProfile/HTTPProfileComponent.vue"),
            },
            {
                path: "/client/http-profile-add",
                name: "HTTPProfileAdd",
                component: () =>
                    import("../views/client/httpProfile/HTTPProfileAddEdit.vue"),
            },
            {
                path: "/client/http-profile-edit/:id",
                name: "HTTPProfileEdit",
                component: () =>
                    import("../views/client/httpProfile/HTTPProfileAddEdit.vue"),
            },

            //end client
            //contact
            {
                path: "/contact/sms-contact",
                name: "SMSContact",
                component: () =>
                    import("../views/smsContact/SMSContactComponent.vue"),
            },
            {
                path: "/contact/sms-contact-add",
                name: "SMSContactAdd",
                component: () =>
                    import("../views/smsContact/SMSContactAdd.vue"),
            },
            {
                path: "/contact/sms-contact-edit/:id",
                name: "SMSContactEdit",
                component: () =>
                    import("../views/smsContact/SMSContactEdit.vue"),
            },
            {
                path: "/contact/sms-contact-upload",
                name: "SMSContactUpload",
                component: () =>
                    import("../views/smsContact/SMSContactUpload.vue"),
            },

            //end contact


            //text and sender id management
            {
                path: "/rate-destination/sms-country-upload",
                name: "smsCountryUpload",
                component: () =>
                    import("../views/rateManagement/smsCountry/SmsCountryUpload.vue"),
            },

            //Sender ID Inventory
            {
                path: "/text-sender-id-management/sender-id-inventory",
                name: "senderIDInventory",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/senderIDInventory/SenderIDInventoryComponent.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/sender-id-inventory-add",
                name: "SenderIDInventoryAdd",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/senderIDInventory/SenderIDInventoryAddEdit.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/sender-id-inventory-edit/:id",
                name: "SenderIDInventoryEdit",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/senderIDInventory/SenderIDInventoryAddEdit.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/sender-id-inventory-assign",
                name: "SenderIDInventoryAssign",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/senderIDInventory/SenderIDInventoryAssign.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/sender-id-translation",
                name: "senderIDTranslation",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/senderIDTranslation/SenderIDTranslationComponent.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/sender-id-translation-add",
                name: "SenderIDTranslationAdd",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/senderIDTranslation/SenderIDTranslationAddEdit.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/sender-id-translation-edit/:id",
                name: "SenderIDTranslationEdit",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/senderIDTranslation/SenderIDTranslationAddEdit.vue"
                        ),
            },
            ///Text Translation
            {
                path: "/text-sender-id-management/text-translation",
                name: "textTranslation",
                component: () =>
                    import("@/views/textSenderIDManagement/textTranslation/TextTranslationComponent.vue"),
            },
            {
                path: "/text-sender-id-management/text-translation-add",
                name: "TextTranslationAdd",
                component: () => import("../views/textSenderIDManagement/textTranslation/TextTranslationAddEdit.vue"),
            },
            {
                path: "/text-sender-id-management/text-translation-edit/:id",
                name: "TextTranslationEdit",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/textTranslation/TextTranslationAddEdit.vue"
                        ),
            },

            //Content Whitelisting
            {
                path: "/text-sender-id-management/content-whitelisting",
                name: "contentWhitelisting",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/contentWhitelisting/ContentWhitelistingComponent.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/content-whitelisting-add",
                name: "ContentWhitelistingAdd",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/contentWhitelisting/ContentWhitelistingAddEdit.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/content-whitelisting-edit/:id",
                name: "ContentWhitelistingEdit",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/contentWhitelisting/ContentWhitelistingAddEdit.vue"
                        ),
            },

            // content blocking
            {
                path: "/text-sender-id-management/content-blocking",
                name: "Text&SenderIDBlocking",
                component: () =>
                    import(
                        "@/views/textSenderIDManagement/textSenderIDBlocking/TextSenderIDBlockingComponent.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/content-blocking-add",
                name: "TextSenderIDBlockingAdd",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/textSenderIDBlocking/TextSenderIDBlockingAddEdit.vue"
                        ),
            },
            {
                path: "/text-sender-id-management/content-blocking-edit/:id",
                name: "TextSenderIDBlockingEdit",
                component: () =>
                    import(
                        "../views/textSenderIDManagement/textSenderIDBlocking/TextSenderIDBlockingAddEdit.vue"
                        ),
            },
            //end text and sender id management


            // rate and destinations
            //SMS Rate Plan
            {
                path: "/rate-destination/sms-rate-plan",
                name: "SMSRatePlan",
                component: () =>
                    import("../views/rateManagement/smsRatePlan/RatePlanComponent.vue"),
            },
            {
                path: "/rate-destination/sms-rate-plan-add",
                name: "RatePlanAdd",
                component: () =>
                    import("../views/rateManagement/smsRatePlan/RatePlanAdd.vue"),
            },
            {
                path: "/rate-destination/sms-rate-plan-edit/:id",
                name: "RatePlanEdit",
                component: () =>
                    import("../views/rateManagement/smsRatePlan/RatePlanEdit.vue"),
            },
            //my rate
            {
                path: "/rate-destination/my-rate",
                name: "MyRatePlan",
                component: () => import("@/views/rateManagement/smsRatePlan/MyRate/MyRateComponent.vue"),
            },
            {
                path: "/rate-destination/sms-rate-plan-details/:id",
                name: "SMSRatePlanDetails",
                component: () =>
                    import("../views/rateManagement/smsRatePlan/RatePlanDetails/RatePlanDetailsComponent.vue"),
            },
            {
                path: "/rate-destination/sms-rate-plan-add-details/:id",
                name: "RatePlanAddDetails",
                component: () =>
                    import(
                        "../views/rateManagement/smsRatePlan/RatePlanAddDetails.vue"
                        ),
            },
            {
                path: "/rate-destination/sms-rate-plan-edit-details/:id",
                name: "RatePlanEditDetails",
                component: () =>
                    import(
                        "../views/rateManagement/smsRatePlan/RatePlanEdit.vue"
                        ),
            },
            {
                path: "/rate-destination/sms-rate-plan-upload-details/:id",
                name: "RatePlanUploadDetails",
                component: () =>
                    import(
                        "../views/rateManagement/smsRatePlan/RatePlanUploadDetails.vue"
                        ),
            },
            // SMS Country
            {
                path: "/rate-destination/sms-country",
                name: "smsCountry",
                component: () =>
                    import("../views/rateManagement/smsCountry/SMSCountryComponent.vue"),
            },
            {
                path: "/rate-destination/sms-country-add",
                name: "smsCountryAdd",
                component: () =>
                    import("../views/rateManagement/smsCountry/SmsCountryAdd.vue"),
            },
            {
                path: "/rate-destination/sms-country-upload",
                name: "SmsCountryUpload",
                component: () =>
                    import("../views/rateManagement/smsCountry/SmsCountryUpload.vue"),
            },
            // rate plan format
            {
                path: "/rate-destination/rate-plan-format",
                name: "RatePlanFormat",
                component: () =>
                    import(
                        "../views/rateManagement/ratePlanFormat/RatePlanFormatComponent.vue"
                        ),
            },
            {
                path: "/rate-destination/rate-plan-format-add",
                name: "ratePlanFormatAdd",
                component: () =>
                    import(
                        "../views/rateManagement/ratePlanFormat/RateplanFormatAddEditView.vue"
                        ),
            },
            {
                path: "/rate-destination/rate-plan-format-edit/:id",
                name: "ratePlanFormatEdit",
                component: () =>
                    import(
                        "../views/rateManagement/ratePlanFormat/RateplanFormatAddEditView.vue"
                        ),
            },
            {
                path: "/rate-destination/ter-rate-import-settings",
                name: "TerminationRatePlanImportSettings",
                component: () => import("@/views/rateManagement/terRateImportSettings/TerRateImportComponent.vue"),
            },
            {
                path: "/rate-destination/ter-rate-import-settings-add",
                name: "TerRatePlanImportAddEdit",
                component: () =>
                    import(
                        "@/views/rateManagement/terRateImportSettings/TerRatePlanImportAddEdit.vue"
                        ),
            },
            {
                path: "/rate-destination/ter-rate-import-settings-edit/:id",
                name: "TerminationRatePlanImportADD",
                component: () =>
                    import(
                        "@/views/rateManagement/terRateImportSettings/TerRatePlanImportAddEdit.vue"
                        ),
            },
            //end rate and destinations


            //bundle management
            {
                path: "/bundle-management/sms-bundle",
                name: "SMSBundle",
                component: () =>
                    import("../views/bundleManagement/smsBundle/SMSBundleComponent.vue"),
            },
            {
                path: "/bundle-management/sms-bundle-add",
                name: "SMSBundleAdd",
                component: () =>
                    import("../views/bundleManagement/smsBundle/SMSBundleAdd.vue"),
            },
            {
                path: "/bundle-management/sms-bundle-edit/:id",
                name: "SMSBundleEdit",
                component: () =>
                    import("../views/bundleManagement/smsBundle/SMSBundleEdit.vue"),
            },

            {
                path: "/bundle-management/bundle-history",
                name: "BundleHistory",
                component: () =>
                    import(
                        "@/views/bundleManagement/bundleHistory/BundleHistoryComponent.vue"
                        ),
            },
            {
                path: "/bundle-management/my-bundle",
                name: "MySMSBundle",
                component: () =>
                    import(
                        "@/views/bundleManagement/mySMSBundle/MyBundleComponent.vue"
                        ),
            },
            //end

            //route management
            {
                path: "/route-management/sms-route",
                name: "SMSRoute",
                component: () =>
                    import("../views/routeManagement/smsRoute/SMSRouteComponent.vue"),
            },
            {
                path: "/route-management/sms-route-add",
                name: "SMSRouteAdd",
                component: () => import("@/views/routeManagement/smsRoute/SMSRouteAddEdit.vue"),
            },

            {
                path: "/route-management/sms-route-edit/:custom_id",
                name: "SMSRouteEdit",
                component: () => import("@/views/routeManagement/smsRoute/SMSRouteAddEdit.vue"),
            },

            // sms translation
            {
                path: "/route-management/sms-translation",
                name: "smsTranslation",
                component: () =>
                    import(
                        "../views/routeManagement/smsTranslation/SMSTranslationComponent.vue"
                        ),
            },

            {
                path: "/route-management/sms-translation-add",
                name: "SMSTranslationAdd",
                component: () =>
                    import(
                        "../views/routeManagement/smsTranslation/SMSTranslationAdd.vue"
                        ),
            },
            {
                path: "/route-management/sms-translation-edit/:id",
                name: "SMSTranslationEdit",
                component: () =>
                    import(
                        "../views/routeManagement/smsTranslation/SMSTranslationEdit.vue"
                        ),
            },

            // manage response code
            {
                path: "/route-management/manage-response-code",
                name: "manageResponseCode",
                component: () =>
                    import(
                        "../views/routeManagement/manageResponseCode/ManageResponseCodeComponent.vue"
                        ),
            },

            {
                path: "/route-management/manage-response-code-add",
                name: "ManageResponseCodeAdd",
                component: () =>
                    import(
                        "../views/routeManagement/manageResponseCode/ManageResponseCodeAdd.vue"
                        ),
            },
            {
                path: "/route-management/manage-response-code-edit/:providerID?/:profileID",
                name: "ManageResponseCodeEdit",
                component: () =>
                    import(
                        "../views/routeManagement/manageResponseCode/ManageResponseCodeEdit.vue"
                        ),
            },
            //end route management


            //campaign management
            {
                path: "/campaign-management/quick-sms",
                name: "QuickSMSNew",
                component: () =>
                    import("@/views/campaignManagement/quickSMS/QuickSMS.vue"),
            },
            {
                path: "/campaign-management/sms-campaign",
                name: "SMSCampaign",
                component: () =>
                    import("@/views/campaignManagement/smsCampaign/SMSCampaignComponent.vue"),
            },
            {
                path: "/campaign-management/sms-campaign-generic",
                name: "GenericCampaign",
                component: () =>
                    import("@/views/campaignManagement/smsCampaign/GenericCampaign.vue"),
            },
            {
                path: "/campaign-management/sms-campaign-dynamic",
                name: "DynamicCampaign",
                component: () =>
                    import("@/views/campaignManagement/smsCampaign/DynamicCampaign.vue"),
            },
            {
                path: "/campaign-management/sms-campaign-request",
                name: "RequestCampaign",
                component: () =>
                    import("@/views/campaignManagement/smsCampaign/RequestCampaign.vue"),
            },

            //pending request
            {
                path: "/campaign-management/pending-requests",
                name: "PendingRequests",
                component: () =>
                    import(
                        "@/views/campaignManagement/pendingRequests/PendingRequestComponent.vue"
                        ),
            },
            // end campaign management

            //finance and Accounts report
            {
                path: "/finance-and-accounts-report/invoice-generation",
                name: "InvoiceGeneration",
                component: () => import("@/views/financeAndAccountsReport/invoiceGeneration/InvoiceGenerationComponent.vue"),
            },
            {
                path: "/finance-and-accounts-report/generated-invoice/",
                name: "BillGenerationComponentForGenerateOrPreview",
                component: () => import("@/views/financeAndAccountsReport/BillGenerationComponent.vue"),
            },
            {
                path: "/finance-and-accounts-report/generated-invoice/:billID",
                name: "BillGenerationComponentForBillID",
                component: () => import("@/views/financeAndAccountsReport/BillGenerationComponent.vue"),
            },
            {
                path: "/finance-and-accounts-report/invoice-log",
                name: "InvoiceLog",
                component: () => import("@/views/financeAndAccountsReport/invoiceLog/InvoiceLogComponent.vue"),
            },
            {
                path: "/finance-and-accounts-report/profit-summary",
                name: "ProfitSummary",
                component: () => import("@/views/financeAndAccountsReport/profitSummary/ProfitSummaryComponent.vue"),
            },
            {
                path: "/finance-and-accounts-report/recharge-history",
                name: "RechargeHistory",
                component: () => import("@/views/financeAndAccountsReport/rechargeHistory/RechargeHistoryComponent.vue"),
            },
            //end finance and Accounts report


            //general report
            {
                path: "/general-reports/sms-contact-report",
                name: "contactReport",
                component: () =>
                    import("@/views/generalReport/contactReport/ContactReportComponent.vue"),
            },
            {
                path: "/general-reports/sms-campaign-report",
                name: "campaignReport",
                component: () =>
                    import(
                        "@/views/generalReport/campaignReport/CampaignReportComponent.vue"
                        ),
            },
            // {
            //     path: "/general-reports/sms-history-report",
            //     name: "SMSHistory",
            //     component: () =>
            //         import("@/views/generalReport/smsHistory/SMSHistoryNew.vue"),
            // },
            {
                path: "/general-reports/sms-history-report",
                name: "SMSHistory",
                component: () =>
                    import("@/views/generalReport/smsHistory/SMSHistoryComponent.vue"),
            },
            // {
            //     path: "/general-reports/sms-history-report-summary",
            //     name: "SMSHistorySummary",
            //     component: () =>
            //         import("@/views/generalReport/smsHistory/SMSHistorySummary.vue"),
            // },
            // {
            //     path: "/general-reports/sms-history-report-log-view/:trafficType/:reference/:requestTime",
            //     name: "SMSLogView",
            //     component: () =>
            //         import("@/views/generalReport/smsHistory/PacketLogger.vue"),
            // },
            {
                path: "/general-reports/performance-report",
                name: "performanceReport",
                component: () =>
                    import(
                        "@/views/generalReport/performanceReport/PerformanceReportComponent.vue"
                        ),
            },
            {
                path: "/general-reports/general-activity-log",
                name: "generalActivityLog",
                component: () =>
                    import(
                        "@/views/generalReport/generalActivityLog/GeneralActivityLogComponent.vue"
                        ),
            },
            {
                path: "/general-reports/alarm-history-report",
                name: "alarmHistory",
                component: () =>
                    import("@/views/generalReport/alarmHistory/AlarmHistoryComponent.vue"),
            },
            //end general report

            //live report
            {
                path: "/live-reports/live-sms-status-report",
                name: "LiveSMSStatus",
                component: () => import("@/views/liveReports/liveSMSStatus/LiveSMSStatusComponent.vue"),
            },
            {
                path: "/live-reports/sms-queue-log",
                name: "SMSQueue",
                component: () =>
                    import("@/views/liveReports/smsQueue/SMSQueueComponent.vue"),
            },
            {
                path: "/live-reports/pending-sms-approval",
                name: "PendingSMSApproval",
                component: () =>
                    import(
                        "@/views/liveReports/pendingSMSApproval/PendingSMSApprovalComponent.vue"
                        ),
            },
            //end live report


            // analytics report
            {
                path: "/analytics/performance-analytics",
                name: "PerformanceAnalytics",
                component: () =>
                    import(
                        "@/views/analytics/performanceAnalytics/PerformanceAnalyticsComponent.vue"
                        ),
            },
            {
                path: "/analytics/revenue-analytics",
                name: "RevenueAnalytics",
                component: () =>
                    import(
                        "@/views/analytics/revenueAnalytics/RevenueAnalyticsComponent.vue"
                        ),
            },

            //end analytics report

            // MO SMS
            {
                path: "/mo-sms/sms-inbox",
                name: "SMSInbox",
                component: () => import("@/views/moSMS/smsInbox/SMSInboxComponent.vue"),
            },
            {
                path: "/mo-sms/sms-inbox/sms-inbox-details-report",
                name: "SMSInboxDetailsReport",
                component: () => import("@/views/moSMS/smsInbox/SMSInboxDetailsReport.vue"),
            },
            {
                path: "/mo-sms/sms-inbox/sms-inbox-summary",
                name: "SMSInboxSummary",
                component: () => import("@/views/moSMS/smsInbox/SMSInboxSummary.vue"),
            },

            // {
            //     path: "/mo-sms/sms-inbox/sms-inbox-log-view/:trafficType/:reference/:requestTime",
            //     name: "MOLogView",
            //     component: () =>
            //         import("@/views/generalReport/smsHistory/PacketLogger.vue"),
            // },

            //end mo sms

            //Settings
            {
                path: "/settings/system-config",
                name: "SystemConfiguration",
                component: () =>
                    import("@/views/settings/systemConfiguration/SystemConfiguration.vue"),
            },
            {
                path: "/settings/manage-currency",
                name: "SystemCurrency",
                component: () =>
                    import("@/views/settings/systemCurrency/SystemCurrency.vue"),
            },
            {
                path: "/settings/sms-dipping",
                name: "SMSDipping",
                component: () =>
                    import("../views/settings/dipping/MNPConfigurationComponent.vue"),

            },
            {
                path: "/settings/sms-dipping-add",
                name: "SMSDippingAdd",
                component: () =>
                    import("../views/settings/dipping/SMSDippingAddEditView.vue"),
            },
            {
                path: "/settings/sms-dipping-edit/:id",
                name: "SMSDippingEdit",
                component: () =>
                    import("../views/settings/dipping/SMSDippingAddEditView.vue"),
            },
            {
                path: "/settings/sms-hlr-lookup",
                name: "HLRLookupConfiguration",
                component: () =>
                    import("../views/settings/hlrLookUp/HLRLookupConfiguration.vue"),

            },
            {
                path: "/settings/system-alert",
                name: "SystemAlert",
                component: () =>
                    import("@/views/settings/systemAlert/SystemAlert.vue"),
            },
            {
                path: "/settings/mail-server-information",
                name: "MailServerInformation",
                component: () =>
                    import("@/views/settings/mailServerInformation/MailServerInformation.vue"),
            },
            {
                path: "/settings/invoice-configuration",
                name: "InvoiceConfiguration",
                component: () =>
                    import("@/views/settings/invoiceConfiguration/InvoiceConfiguration.vue"),
            },
            // end settings


            //Users
            {
                path: "/users/manage-users",
                name: "ManageUsers",
                component: () =>
                    import(
                        "../views/users/manageUsers/ManageUsersComponent.vue"
                        ),
            },
            {
                path: "/users/manage-users-add",
                name: "ManageUsersAdd",
                component: () =>
                    import(
                        "@/views/users/manageUsers/ManageUsersAddEdit.vue"
                        ),
            },
            {
                path: "/users/manage-users-edit/:id",
                name: "ManageUsersEdit",
                component: () =>
                    import(
                        "@/views/users/manageUsers/ManageUsersAddEdit.vue"
                        ),
            },

            {
                path: "/users/manage-account-manager",
                name: "ManageAccountManager",
                component: () =>
                    import(
                        "../views/users/manageAccountManagers/ManageAccountManagersComponent.vue"
                        ),
            },
            {
                path: "/users/manage-account-manager-add",
                name: "ManageAccountManagerAdd",
                component: () =>
                    import(
                        "@/views/users/manageAccountManagers/ManageAccountManagerEdit.vue"
                        ),
            },
            {
                path: "/users/manage-account-manager-edit/:id",
                name: "ManageAccountManagerEdit",
                component: () =>
                    import(
                        "@/views/users/manageAccountManagers/ManageAccountManagerEdit.vue"
                        ),
            },

            {
                path: "/users/manage-roles",
                name: "ManageRoles",
                component: () =>
                    import(
                        "@/views/users/manageRoles/ManageRolesComponent.vue"
                        ),
            },
            {
                path: "/users/manage-roles-add",
                name: "RoleAdd",
                component: () => import("@/views/users/manageRoles/ManageRolesAddEdit.vue"),
            },
            {
                path: "/users/manage-roles-edit/:id",
                name: "RoleEdit",
                component: () => import("@/views/users/manageRoles/ManageRolesAddEdit.vue"),
            },
            // end users

            //Security
            // {
            //     path: "/security/black-or-white-list-ips",
            //     name: "RestrictedIPs",
            //     component: () =>
            //         import("@/views/security/restrictedIPs/RestrictedIPs.vue"),
            // },
            {
                path: "/security/black-or-white-list-ips/:ip?",
                name: "RestrictedIPs",
                component: () =>
                    import("@/views/security/restrictedIPs/RestrictedIPComponent.vue"),
            },
            {
                path: "/security/un-auth-access-params",
                name: "UnAuthorizeAccessLog",
                component: () =>
                    import(
                        "@/views/security/unAuthorizeAccessLog/UnAuthorizedAccessComponent.vue"
                        ),
            },
            {
                path: "/security/payment-security",
                name: "PaymentSecurity",
                component: () =>
                    import("@/views/security/paymentSecurity/PaymentSecurity.vue"),
            },
            {
                path: "/security/payment-security-add",
                name: "PaymentSecurityAdd",
                component: () => import("@/views/security/paymentSecurity/PaymentSecurityAdd.vue"),
            },
            {
                path: "/security/active-login",
                name: "activeLogin",
                component: () => import("@/views/security/activeLogin/activeLogin.vue"),
            },
            // security/black-or-white-list-ips
            // end security

            //testing
            {
                path: "/test",
                name: "Testing",
                component: () => import("../views/TestingView.vue"),
            },
        ],

    },
    {
        path: "/finance-and-accounts-report/generated-invoice/",
        name: "BillGenerationComponent",
        component: () => import("@/views/financeAndAccountsReport/BillGenerationComponent.vue"),
    },
    {
        path: "/finance-and-accounts-report/generated-invoice/:billID",
        name: "BillGenerationComponent",
        component: () => import("@/views/financeAndAccountsReport/BillGenerationComponent.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
/**
 * Scrolls the window to the top.
 * <p> Useful when a new page(component) is mounted.
 *
 * <p>This function triggers a scroll to the top of the window, resetting the vertical scroll position to 0.</p>
 *
 * @since 5.0
 */
function scrollToTop() {
    console.log("Scroll to top triggered.")
    window.scrollTo(0, 0);
}
/**
 * Vue Router navigation guard to scroll to the top before each route navigation.
 *
 * <p>This navigation guard is typically used in Vue Router to trigger the scroll to the top of the page
 * before navigating to a new route. It utilizes the {@code scrollToTop()} function and proceeds to the next route.</p>
 *
 * @param to   The route being navigated to.
 * @param from The route being navigated from.
 * @param next The function to call to proceed to the next route.
 *
 * @since 5.0
 */
router.beforeEach((to, from, next) => {
    scrollToTop(); // Call the scrollToTop function
    next();
});

export default router;
