// form.js

const formModule = {
    namespaced: true,

    state: {
        formStructureData: [],
        formData: {}
    },
    mutations: {

        setFormStructureData(state, formStructureData){
            state.formStructureData = formStructureData;

        },

        setFormData(state, formData){
            state.formData = formData;

        },

    },
    actions: {
        setFormStructureData({commit},formStructureData ){
            console.log("setFormStructureData  ",  formStructureData);
            commit('setFormStructureData', formStructureData);
        },

        setFormData({commit},formData ){
            console.log("setFormData  ",  formData);
            commit('setFormData', formData);
        },







    },
    getters: {

        formStructureData:(state) => state.formStructureData,
        formData:(state) => state.formData,

    },
};

export default formModule;

